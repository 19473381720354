<template>
    <div
        @click="changeRoute"
        style="cursor: pointer;"
        data-scroll
        :data-scroll-id="name"
        class="wine-cradle"
    >
        <img
            data-scroll
            data-scroll-id="imageParallax"
            :src="imagePath"
            class="image"
            alt=""
        />
        <div class="content">
            <div class="icon" v-html="item.icon"></div>
            <h2 class="header" v-html="item.teaser"></h2>
            <header-title :name="item.title" text-color="white" />
            <span class="description">{{ item.description }}</span>
            <div @click="changeRoute" class="explore-container">
                <Arrow color="#E5B676" />
                <span class="title">{{ _tr("Explore") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Arrow from "@/components/custom/Arrow";
import HeaderTitle from "@/components/custom/HeaderTitle";
import { mapGetters } from "vuex";
import first from "lodash/first";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        Arrow,
        HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        imagePath() {
            return (
                this.data?.list?.[0]?.image?.[0]?.devices?.[
                    this.device?.type
                ] || require(`@/assets/images/${this.background}.jpg`)
            );
        },
        item() {
            let item = this.data?.list?.[0];
            return {
                title: item?.title,
                description: item?.description,
                teaser: item?.teaser,
                link: item?.link,
                icon: item?.icon
            };
        },
        slug() {
            return first(this.data?.list)?.link || first(this.data?.list)?.slug;
        }
    },
    methods: {
        changeRoute() {
            this.$router
                .push({ path: `/${this.lang}/${this.slug}` })
                .catch(e => {
                    console.log(e);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.wine-cradle /deep/ {
    .icon {
        margin-bottom: 30px;
    }
    @include center-children;
    position: relative;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    height: 610px;
    background: #0a141c;
    @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        height: 450px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        height: 425px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        height: 425px;
    }
    @media only screen and (max-width: 767px) {
        height: 325px;
    }

    .image {
        position: absolute;
        height: 120%;
        top: -10%;
        width: 100%;
        object-fit: cover;
        opacity: 0.5;
    }

    .content {
        @include center-children;
        flex-direction: column;
        z-index: 1;
        position: relative;

        .header {
            color: #e5b676;
            font-weight: normal;
            font-size: 34px;
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                font-size: 21px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }

            span {
                font-weight: normal;
                font-size: 13px;
                color: #fff;
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(calc(100% + 5px)) translateY(5px);
                @media only screen and (max-width: 1365px) {
                    transform: translateX(calc(100% + 5px));
                }
            }
        }

        .description {
            color: white;
            font-size: 21px;
            font-family: "Noto-Sans", "Noto-Sans-georgian";
            max-width: 870px;
            text-align: center;
            margin-top: 34px;
            font-weight: normal;
            will-change: transform;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                max-width: 730px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                max-width: 610px;
                font-size: 16px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                font-size: 16px;
                margin-top: 21px;
                max-width: 475px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        .header-title {
            font-weight: normal;
            line-height: unset;
        }
    }

    .explore-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 34px;
        // @media only screen and (max-width: 1023px) {
        //     margin-top: 21px;
        // }

        &:hover {
            svg {
                transform: translateX(-15px);
            }

            .title {
                opacity: 0.6;
            }
        }

        svg,
        .title {
            transition: all 0.6s $ease-out;
        }

        .title {
            font-size: 16px;
            color: white;
            letter-spacing: 1.5px;
            margin-left: 10px;
        }
    }
}

@keyframes slide {
    50% {
        transform: translateX(-30px);
    }
    100% {
        transform: translateX(0px);
    }
}
</style>
