<template>
  <svg width="22.23" height="11.775" viewBox="0 0 22.23 11.775">
    <g  id="Group_55" data-name="Group 55"
       transform="translate(21.523 0.707) rotate(90)">
      <line id="Line_3" data-name="Line 3" x2="5.192" y2="5.431" transform="translate(5.17 0)" fill="none"
            :stroke="color" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_4" data-name="Line 4" x1="5.17" y2="5.431" transform="translate(0 0)" fill="none" :stroke="color"
            stroke-linecap="round" stroke-width="1"/>
      <line id="Line_5" data-name="Line 5" y2="20.265" transform="translate(5.181 0.759)" fill="none" :stroke="color"
            stroke-linecap="round" stroke-width="1"/>
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
